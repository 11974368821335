label {
    display: block;
    margin-top: 10px;
}

#root .row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.Register, .Login{
    margin: 0;
    height: 100%;
    overflow-x: hidden
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 50px auto 25px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    display: block;
    margin: auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.css-11unzgr {
    max-height: 150px !important;
    text-align: left;
}

.css-1jllj6i-control {
    display: none !important;
}

#root > div > div.AppContent > div > div > div > div > div > div.col-lg-7.col-md-7.col-sm-12.col-xs-12 > form > div > div:nth-child(6) > div > div:nth-child(2) {
    position: inherit !important;
}

.Login > div > div > div > div > div {
    top: -30px;
    right: -1%;
}

@media all and (min-width: 480px) {
    .Register, .Login {
        padding: 60px 0;
    }
}

@media (max-width: 575px) {
    .Register, .Login {
        padding: 0;
    }
    .exit_class{
        top: 5%;
        left: 85%;
        position: absolute;
    }
}

@media (min-width: 1381px) {
    .Login > div > div > div {
        max-width: 700px;
        margin: 0 auto;
    }
}

@media (min-width: 1381px) {
    .content_forget > div > div > div {
        max-width: 710px;
        margin: 0 auto;
    }
}


.title_forget{
    text-align: center;
}

.content_forget{
    margin-top: 5%;
}

.content_mail{
    display: contents;

}

.forgot-img{
    width: 96px;
    display: block;
    margin: auto;
    margin-top: 1%;
}

@media (min-width: 800px) and (max-width: 1000px) {
    .title_forget{
        margin-left: 20%;
    }
}

#form_password .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #007bff;
    font-size: 14px;
}

.form_class {
    margin-top: 5%;
}


@media (min-width: 1400px) {

    .whitePanel_class{
        margin-left: -5%;
        margin-right: -5%;
    }

    .right_button{
        margin-right: 5%;
    }
}


