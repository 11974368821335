.toolbar {
  width: 100%;
  height: 90px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 0.45;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items a {
  z-index: 1400;
  color: black;
  text-decoration: none;
  font-size: 20px;
  margin: 0 30px 0 5px;
  padding: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 10px;
}

@media (min-width: 980px) and (max-width: 1200px) {
  .toolbar_navigation-items a {
    z-index: 1400;
    color: black;
    text-decoration: none;
    font-size: 20px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-radius: 10px;
  }
}


.toolbar_navigation-items li:hover a,
.toolbar_navigation-items a.active {
  color: white;
  background-color: #37ab49;
}

.toolbar_navigation-items a.active:after, .toolbar_navigation-items li:hover a:after {
  z-index: 0;
  border-left: 20px solid #37ab49;
  border-bottom: 20px solid transparent;
  bottom: -15px;
  content: "";
  position: absolute;
  right: 35px;
}
  
.toolbar_navigation-items li {
  position: relative;
}

.dropdown_button {
  border-color: transparent;
}

.dropdown_button:hover, .dropdown_button:hover,
.dropdown_button:active, .dropdown_button:active,
.dropdown_button:focus, .dropdown_button:focus {
  background-color: #37ab49 !important;
  border-color: #37ab49 !important;
}

.dropdown-menu {
  font-size: 2rem;
  z-index: 1400;
}

.dropdown-toggle::after {
  display: none;
}

.languages {
  position: absolute;
  right: 30px;
}

.toolbar_navigation-items {
  margin-left: 1vw;
}

@media (max-width: 991px) {
  .toolbar_navigation-items {
    display: none;
  }

  .dropdown_button {
    display: none;
  }

  .languages {
    display: none;
  }

  .spacer {
    flex: 1;
  }
}

@media (min-width: 992px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}
