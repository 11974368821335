.JoinRoom {
    margin: 0;
    height: 100%;
    overflow: hidden
}

.JoinRoom .container__img img {
    width: 215px;
    height: auto;
}

.JoinRoom > div > div > div > div > div {
    top: -30px;
    right: -1%;
}

@media all and (min-width: 480px) {
    .JoinRoom {
        padding: 60px 0;
    }
}

@media (min-width: 1381px) {
    .JoinRoom > div > div > div {
        max-width: 700px;
        margin: 0 auto;
    }
}