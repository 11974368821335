@font-face {
    font-family: 'tui-calendar-font-icon';
    src: url('../../../static/font/icon.eot') format('embedded-opentype'),
         url('../../../static/font/icon.ttf') format('truetype'),
         url('../../../static/font/icon.woff') format('woff'),
         url('../../../static/font/icon.svg') format('svg');
}

.calendar-icon {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
}

.ic_view_month {
    background: url('../../../static/images/ic-view-month.png') no-repeat;
}

.ic_view_week {
    background: url('../../../static/images/ic-view-week.png') no-repeat;
}

.ic_view_day {
    background: url('../../../static/images/ic-view-day.png') no-repeat;
}

.ic-arrow-line-left {
    background: url('../../../static/images/ic-arrow-line-left.png') no-repeat;
}

.ic-arrow-line-right {
    background: url('../../../static/images/ic-arrow-line-right.png') no-repeat;
}