:root {
  --buttonColor: #0079c2;
  --buttonHoverColor: #005D91;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.button{
  position: relative;
  z-index: 1;
  background: var(--buttonColor);
  text-align: center;
  display: table-cell;
  padding: 15px 40px 15px 40px;
  color: #fff;
  text-decoration: none;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  font-size: 18px;
  box-shadow: 5px 5px 10px #6e6e6e;
}

.button:hover {
  cursor: pointer;
  background: var(--buttonHoverColor);
  color: #fff;
  text-decoration: none;
}

.button-left:before {
  content: "";
  border-left: 30px solid transparent;
  border-right: 20px solid var(--buttonColor);
  border-top: 12px solid transparent;
  border-bottom: 20px solid var(--buttonColor);
  left: -20px;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

.button-right:before {
  content: "";
  border-left: 20px solid var(--buttonColor);
  border-right: 30px solid transparent;
  border-top: 12px solid transparent;
  border-bottom: 20px solid var(--buttonColor);
  right: -20px;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

.button-right:hover:before {
  border-left: 20px solid var(--buttonHoverColor);
  border-bottom: 20px solid var(--buttonHoverColor);
}

.button-left:hover:before {
  border-right: 20px solid var(--buttonHoverColor);
  border-bottom: 20px solid var(--buttonHoverColor);
}

.button-right .no-hover:hover {
  border-left: solid var(--buttonHoverColor);
  border-bottom: solid var(--buttonHoverColor);
}

.button-left .no-hover {
  border-right: solid var(--buttonHoverColor);
  border-bottom: solid var(--buttonHoverColor);
}

.btn-default {
  border-color: var(--buttonColor);
  font-size: 15px;
}

.btn-default[disabled]:hover, .btn-default:hover,
.btn-default[disabled]:active, .btn-default:active,
.btn-default[disabled]:focus, .btn-default:focus {
  background-color: var(--buttonColor);
  border-color: var(--buttonColor);
  font-size: 15px;
}