.RoomCreator {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.RoomCreator .container__img img {
    width: 215px;
    height: auto;
}

.RoomCreator .container__text {
    font-size: 20px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RoomCreator > div > div > div > div > div {
    top: -30px;
    right: -1%;
}

@media all and (min-width: 480px) {
    .RoomCreator {
        padding: 60px 0;
    }
}

@media (min-width: 1381px) {
    .RoomCreator > div > div > div {
        max-width: 700px;
        margin: 0 auto;
    }
}
