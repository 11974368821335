.Room {
    margin: 0;
    height: 100%;
    overflow: hidden;
    padding: 60px 0;
}

.meeting_img{
    margin: 5%;
    margin-left: 43%;
}