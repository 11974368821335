.WhitePanel {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
}

@media (min-width: 768px) {
    .alignment {
        margin-left: 25%;
    }
}
