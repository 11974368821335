.Contact {
    margin: 0;
    height: 100%;
    overflow: hidden
}

.Contact .contact__logo a {
    color: black;
}

.Contact .form-control {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.Contact textarea {
    resize: none;
}

.contact-fields {
    margin-top: 50px;
    font-size: 15px;
}

.phone_input{
    font-size: 18px !important;
}

.PhoneInputCountryIcon--border {
    background-color: initial !important;
    box-shadow: none !important;
    margin-top: -40%;
}

@media all and (min-width: 480px) {
    .Contact {
        padding: 60px 0;
    }
}

@media (max-width: 768px) {

    .Contact .container__img img {
        width: 150px;
    }

    .Contact .container__text {
        font-size: 9px;
    }
}

@media (min-width: 769px) and (max-width: 1040px) {
    .Contact > div > div > div {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -307.5px;
    }
}