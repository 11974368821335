.dashboard{
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    padding-top: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 96.5vw;
    font-size: 16px;
    overflow-x: hidden;
}

.chartclass{
    background-color: white;
/*    width: 800px !important;
    height: 400px !important;*/
}

.no_transition {
    -webkit-transform: none !important;
    transform: none !important;;
}

.contain_dashboard{
    background-color: white;
    border-bottom: 12px solid #37ab49;
}

.font_legend{
    font-size: 14px;
}

.font_legend .recharts-legend-wrapper {
position: absolute !important;
    width: 0px !important;
    right: -5px !important;
    left: auto !important;
    top: 15px !important;
}

.details_graph{
    margin-top: 12% !important;
}

.title_dashboard{
    text-align: center;
    margin-left: 32%;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 2%;
}
.date_choose{
    display: contents;
}

.calendar-icon {
    height: 18px;
}

.class_periode{
    display: contents;
}

.move_prev{
    margin-right: 1%;
    margin-left: 2%;
}

.move_next{
    margin-left: 1%;
}

.move_all{
    margin-bottom: 5%;
}

.period_row_1{
    margin-left: -2.8%;
}

.period_row_2{
    margin-left: 21.5%;
}

.button_download{
    margin-top: 100%;
}

.button_download a:hover{
    text-decoration: none;
    color: white;
}

@media (max-width: 1200px) {
    .button_csv {
        display: -webkit-box;
        margin-left: 45%;
        margin-top: -10%;
    }
}

@media (max-width: 700px) {
    .button_csv {
        display: -webkit-box;
        margin-left: 30%;
        margin-top: -25%;
    }
    .my-custom-scrollbar {
        margin-left: 10%;
        width: 90%;
    }
    .class_periode{
        display: block;
        margin-left: 42%;
    }
    .move_prev{
        margin-left: 19%;
    }
    .title_dashboard{
        margin-left: 0% !important;
    }


}

@media (max-width: 1000px) {
    .my-custom-scrollbar {
        margin-left: 12%;
    }

}

.my-custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
}


#ministres thead {
    display:block;
}
#ministres tbody {
    display:grid;
    max-height:200px; /* 5 times the equivalent of a text "size". */
    overflow-y:scroll;
}

#ministres thead tr th { /* column 1 ! */
    width:10em;
}

#ministres tbody tr td  { /* column 1 ! */
    width:10em;
}

.header_style{
    background-color: #0056b3;
    color: white;
}

.table thead th{
    border-bottom: none !important;
}

.second_head{
    width: 15em !important;
}

.first_data_class{
    width: 100%;
}

.class_pie{
    height: 90% !important;
    width: 75% !important;
    margin-left: 16%;
}

