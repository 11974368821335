.Share {
    font-size: 15px !important;
}

.tab-content>.active {
    display: contents;
}

.emails, .RoomShareDialog .form-group {
    margin-top: 30px !important;
    font-size: 15px !important;
}

.react-multi-email [data-tag] {
    font-size: 15px !important;
}

.RoomShareDialog .PhoneInput .PhoneInputCountryIcon {
    height: 1em !important;
}

.close-button {
    color: #007bff;
    font-size: 10px;
    height: 40px !important;
    flex-direction: inherit !important;
    margin: 0 !important;
    float: right !important;
}

.close-button:hover {
    color: #0056b3;
}

.MuiDialogContent-root:first-child {
    padding-top: 0 !important;
}

.RoomShareDialog .add {
    cursor: pointer;
    color: green;
    -webkit-appearance: none;
    margin-top: 8vh;
}

.RoomShareDialog .PhoneInput input {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    font-size: 16px;
    border: 1px solid #d6d4d4;
    border-radius: 3px;
}

.RoomShareDialog .PhoneInput .PhoneInputCountrySelect {
    font-size: 12px;
}

.RoomShareDialog .PhoneInput .PhoneInputCountryIcon {
    height: 2em;
    width: 3em;
}

.RoomShareDialog .PhoneInput .PhoneInputCountrySelectArrow {
    height: 0.45em;
    width: 0.45em;
}

.RoomShareDialog .items li {
    float: left;
}

.RoomShareDialog ul.items
{
    text-align: center;
    list-style-type: none;
    width: 500px;
}

.RoomShareDialog ul.items li
{
    display: inline;
    margin-right: 10px;
    color: red;
    cursor: pointer;
}
    .size_dialog .MuiDialog-paperFullWidth {
        height: 252px;
    }




@media (max-width: 550px) {
    .Share {
        font-size: 12px !important;
    }
}

@media (max-width: 576px) {
    .col-xs-10{
        flex: 0 0 83.3333333333333%;
        max-width: 83.333333333333333%;
    }

    .col-xs-2{
        flex: 0 0 16.6666666666666667%;
        max-width: 16.6666666666666667%;
    }
}