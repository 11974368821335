.Doctor {
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px 30px 20px 30px;
    width: 96.5vw;
    overflow-x: hidden;
}

.Doctor .tui-full-calendar-dayname-container {
    overflow-y: auto;
}

.Doctor .top-menu {
    background-color: white;
    border-top: 1px solid #e5e5e5;
}

.Doctor #menu {
    padding: 16px;
}

.Doctor .calendar-type {
    float: right;
}

.Doctor #dropdownMenu-calendarType {
    padding: 0 8px 0 11px;
}

.Doctor .btn {
    border-radius: 25px;
    border-color: #ddd;
}

.Doctor .btn:hover {
    border: solid 1px #bbb;
    background-color: #fff;
}

.Doctor .btn:active {
    background-color: #f9f9f9;
    border: solid 1px #bbb;
    outline: none;
}

.Doctor .btn:disabled {
    background-color: #f9f9f9;
    border: solid 1px #ddd;
    color: #bbb;
}

.Doctor #calendarTypeName {
    min-width: 62px;
    display: inline-block;
    text-align: left;
    line-height: 30px;
}

.Doctor .calendar-icon {
    width: 14px;
    height: 14px;
}

.Doctor .dropdown-menu {
    top: 25px;
    padding: 3px 0;
    border-radius: 2px;
    border: 1px solid #bbb;
}

.Doctor .dropdown-menu > li > a {
    padding: 9px 12px;
    cursor: pointer;
}

.Doctor .dropdown-menu > li > a:hover {
    background-color: rgba(81, 92, 230, 0.05);
    color: #333;
}

.Doctor .tui-full-calendar-left {
    padding-left: 13px;
    padding-top: 5px;
    font-size: 12px;
}

.dropdown-menu-title .calendar-icon {
    margin-right: 8px;
}

.Doctor .move-today {
    padding: 0 14px;
    line-height: 30px;
    margin-left: 5px;
}

.Doctor .move-day {
    padding: 8px;
    font-size: 0;
    margin-left: 1px;
}

.Doctor .holidays {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
}

.Doctor .render-range {
    padding-left: 12px;
    font-size: 19px;
    vertical-align: middle;
}

.Schedule-content {
    width: 350px;
    margin: auto;
}

.schedule-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #007bff;
    font-size: 10px;
    flex-direction: inherit;
    margin: 0;
    float: right;
}

.ScheduleDetailsPaneContent {
    font-size: 12px;
}

.CreateHolidaysPaneContent .holidays-icon {
    width: 96px;
    display: block;
    margin: auto;
}

.title_recap{
    text-align: center;
    margin-bottom: 5%;
    font-weight: bold;
}

.CreateHolidaysPaneContent .trash-icon {
    margin-left: 68px !important;
}

.CreateHolidaysPaneContent .trash-icon, .CreateHolidaysPaneContent .times-icon {
    margin-left: 5px;
    color: red;
    cursor: pointer;
}

.CreateHolidaysPaneContent .add-holiday {
    display: block;
    margin: 0 auto;
}

.CreateHolidaysPaneContent .add-holiday[disabled]:hover, .CreateHolidaysPaneContent .add-holiday:hover,
.CreateHolidaysPaneContent .add-holiday[disabled]:active, .CreateHolidaysPaneContent .add-holiday:active,
.CreateHolidaysPaneContent .add-holiday[disabled]:focus, .CreateHolidaysPaneContent .add-holiday:focus {
    background-color: #28a745 !important;
}

.CreateHolidaysPaneContent .add-holiday-plus {
    color: #28a745;
}

.CreateHolidaysPaneContent .add-holiday[disabled]:hover .add-holiday-plus, .CreateHolidaysPaneContent .add-holiday:hover .add-holiday-plus,
.CreateHolidaysPaneContent .add-holiday[disabled]:active .add-holiday-plus, .CreateHolidaysPaneContent .add-holiday:active .add-holiday-plus,
.CreateHolidaysPaneContent .add-holiday[disabled]:focus .add-holiday-plus, .CreateHolidaysPaneContent .add-holiday:focus .add-holiday-plus {
    color: white;
}

.CreateHolidaysPaneContent #controlled-tab-example {
    font-size: 12px;
}

.CreateHolidaysPaneContent ul.nav.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.CreateHolidaysPaneContent .tab-content .btn-default[disabled]:hover,
.CreateHolidaysPaneContent .tab-content .btn-default:hover,
.CreateHolidaysPaneContent .tab-content .btn-default[disabled]:active,
.CreateHolidaysPaneContent .tab-content .btn-default:active,
.CreateHolidaysPaneContent .tab-content .btn-default[disabled]:focus,
.CreateHolidaysPaneContent .tab-content .btn-default:focus {
    background-color: #e3e3e3;
    border-color: #d6d6d6;
}

.CreateHolidaysPaneContent .tab-content .btn.active.focus,
.CreateHolidaysPaneContent .tab-content .btn.active:focus,
.CreateHolidaysPaneContent .tab-content .btn.focus,
.CreateHolidaysPaneContent .tab-content .btn:active.focus,
.CreateHolidaysPaneContent .tab-content .btn:active:focus,
.CreateHolidaysPaneContent .tab-content .btn:focus {
    outline: none;
    box-shadow: none;
}

.CreateHolidaysPaneContent .validate-holidays {
    float: right;
}

.CreateHolidaysPaneContent .checkbox {
    text-align: center;
    margin: 0;
}

.CreateHolidaysPaneContent .checkbox label {
    margin: 0;
}

.CreateHolidaysPaneContent .checkbox label input {
    position: inherit;
}

.CreateHolidaysPaneContent .absence {
    margin-top: 15px;
}

.AddHolidaysDatesPaneContent .holidays-dates {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.AddHolidaysDatesPaneContent .holidays-dates > div:nth-child(1) {
    margin-right: 5px;
}

.AddHolidaysDatesPaneContent .holidays-dates .MuiOutlinedInput-root {
    font-size: 12px;
}

.AddHolidaysDatesPaneContent .create-holiday {
    float: right;
}

.AddHolidaysDatesPaneDialog > div.MuiDialog-container.MuiDialog-scrollPaper > div {
    max-width: 500px;
}

.weekMeetings {
    margin-right: 5px;
}

.MeetingPaneContent .validate-meeting #validate_meeting {
    float: right;
}

.displayNoneButton {
    display: none;
}

.displayButton{
    display: block;
}


.MeetingPaneContent .validate-meeting #cancel_meeting {
    float: left;
}

.btn_delete{
    background: white;
    border-color: red;
    color: red;
    margin-left: 2%;
    padding: .5rem 1rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.join_button:hover{
    color: white;
}

.btn_delete:hover{
    background: red;
    border-color: red;
    color: white;
}


@media (min-width: 768px) {
    .CreateHolidaysPaneContent .calendar-img {
        margin-top: 50px;
    }
}

@media (max-width: 367px) {
    .CreateHolidaysPaneDialog > div.MuiDialog-container.MuiDialog-scrollPaper > .MuiDialog-paperFullWidth {
        width: 100%;
    }

    .CreateHolidaysPaneDialog > div.MuiDialog-container.MuiDialog-scrollPaper > .MuiDialog-paper {
        margin: 0;
    }
}

@media (max-width: 700px){
    .tui-full-calendar-dayname-name{
        display: none;
    }
    .Secretariat .calendar-type{
        float: none;
        margin: 10%;
    }
    .btn_delete{
        margin: 10%;
    }
    .Doctor .calendar-type{
        margin: 15%;
    }
    .Doctor .render-range{
        display: block;
        margin-left: 10%;
    }
}