.Secretariat {
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px 30px 20px 30px;
    width: 96.5vw;
    overflow-x: hidden;
}

.Secretariat .tui-full-calendar-dayname-container {
    overflow-y: auto;
}

.Secretariat .top-menu {
    background-color: white;
    border-top: 1px solid #e5e5e5;
}

.Secretariat #menu {
    padding: 16px;
}

.Secretariat .calendar-type {
    float: right;
}

.Secretariat #dropdownMenu-calendarType {
    padding: 0 8px 0 11px;
}

.Secretariat .btn {
    border-radius: 25px;
    border-color: #ddd;
}

.Secretariat .btn:hover {
    border: solid 1px #bbb;
    background-color: #fff;
}

.Secretariat .btn:active {
    background-color: #f9f9f9;
    border: solid 1px #bbb;
    outline: none;
}

.Secretariat .btn:disabled {
    background-color: #f9f9f9;
    border: solid 1px #ddd;
    color: #bbb;
}

.Secretariat #calendarTypeName {
    min-width: 62px;
    display: inline-block;
    text-align: left;
    line-height: 30px;
}

.Secretariat .calendar-icon {
    width: 14px;
    height: 14px;
}

.Secretariat .dropdown-menu {
    top: 25px;
    padding: 3px 0;
    border-radius: 2px;
    border: 1px solid #bbb;
}

.Secretariat .dropdown-menu > li > a {
    padding: 9px 12px;
    cursor: pointer;
}

.Secretariat .dropdown-menu > li > a:hover {
    background-color: rgba(81, 92, 230, 0.05);
    color: #333;
}

.meeting-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #007bff;
    font-size: 10px;
    flex-direction: inherit;
    margin: 0;
    float: right;
}

.dropdown-menu-title .calendar-icon {
    margin-right: 8px;
}

.Secretariat .move-today {
    padding: 0 14px;
    line-height: 30px;
    margin-left: 5px;
}

.Secretariat .move-day {
    padding: 8px;
    font-size: 0;
    margin-left: 1px;
}

.Secretariat .render-range {
    padding-left: 12px;
    font-size: 19px;
    vertical-align: middle;
}

.Secretariat .tui-full-calendar-left {
    padding-left: 13px;
    padding-top: 5px;
    font-size: 12px;
}

.MeetingPaneContent .meeting-icon {
    width: 96px;
    display: block;
    margin: auto;
}

.MeetingPaneContent .meeting-code {
    text-align: center;
    color: #0079c2;
}

.MeetingPaneContent .dates-container {
    display: flex;
}

.MeetingPaneContent .dates-container .MuiOutlinedInput-root {
    font-size: 14px;
}

.MeetingPaneContent .creation-dates {
    text-align: center;
    flex: 1;
}

.MeetingPaneContent .add-people {
    margin-top: 10px;
}

.MeetingPaneContent .add-people p {
    margin-bottom: 0;
}

.MeetingPaneContent .internal-people, .MeetingPaneContent .external-people,
.SummaryPaneContent .internal-people, .SummaryPaneContent .external-people {
    float: left;
    width: 50%;
}

.MeetingPaneContent .internal-people table, .SummaryPaneContent .internal-people table {
    border-collapse: separate;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.MeetingPaneContent .internal-people table tr,
.MeetingPaneContent .internal-people table th,
.SummaryPaneContent .internal-people table tr,
.SummaryPaneContent .internal-people table th {
    border-top-left-radius: 5px;
}

.MeetingPaneContent .internal-people table .button-add-internal {
    border-bottom-left-radius: 5px;
}

.MeetingPaneContent .external-people table:not(.nested-table),
.SummaryPaneContent .external-people table:not(.nested-table) {
    border-collapse: separate;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.MeetingPaneContent .external-people table .external,
.MeetingPaneContent .external-people table .external th,
.SummaryPaneContent .external-people table .external,
.SummaryPaneContent .external-people table .external th {
    border-top-right-radius: 5px;
}

.MeetingPaneContent .external-people table .button-add-external {
    border-bottom-right-radius: 5px;
}

.MeetingPaneContent .external-people .nested-table {
    margin-bottom: 0;
}

.MeetingPaneContent .external-people .nested-table thead tr {
    background-color: #ffffff;
}

.MeetingPaneContent .peoples::after, .SummaryPaneContent .peoples::after {
    content: "";
    clear: both;
    display: table;
}

.MeetingPaneContent .add {
    cursor: pointer;
    color: green;
}

.MeetingPaneContent .validate-meeting #validate_meeting {
    float: right;
}

.displayNoneButton {
    display: none;
}

.displayButton{
    display: block;
}

.MeetingPaneContent .validate-meeting #cancel_meeting {
    float: left;
}

.btn_delete{
    background: white;
    border-color: red;
    color: red;
}
.btn_delete:hover{
    background: red;
    border-color: red;
    color: white;
}


.MeetingPaneContent .remove {
    cursor: pointer;
    color: red;
}

.AddExternalPane .add-external, .AddExternalPane .edit-external {
    float: right;
}

.AddExternalPane .delete-external {
    float: left;
}

.AddExternalPane .delete-external .delete[disabled]:hover, .AddExternalPane .delete-external .delete:hover,
.AddExternalPane .delete-external .delete[disabled]:active, .AddExternalPane .delete-external .delete:active,
.AddExternalPane .delete-external .delete[disabled]:focus, .AddExternalPane .delete-external .delete:focus {
    background-color: red;
}

.AddExternalPaneDialog .close-button, .AddInternalPaneDialog .close-button {
    position: absolute;
    color: #007bff;
    top: 0;
    right: 5px;
}

.AddExternalPane .PhoneInput input {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    font-size: 16px;
    border: 1px solid #d6d4d4;
    border-radius: 3px;
}

.AddExternalPane .PhoneInput .PhoneInputCountrySelect {
    font-size: 12px;
}

.AddExternalPane .PhoneInput .PhoneInputCountryIcon {
    height: 2em;
    width: 3em;
}

.AddExternalPane .PhoneInput .PhoneInputCountrySelectArrow {
    height: 0.45em;
    width: 0.45em;
}

.AddInternalPane .css-kj6f9i-menu {
    position: inherit;
}

.SummaryPaneContent {
    font-size: 15px;
}

.SummaryPaneContent .mail-switch,
.SummaryPaneContent .sms-switch {
    height: 20px;
    margin-left: 5px;
}

.SummaryPaneContent .cancel-button {
    float: left;
}

.SummaryPaneContent .validate-button {
    float: right;
}

.SummaryPaneContent .mails {
    margin-right: 5px;
}

.SummaryPaneContent .notify-guests {
    width: 265px;
}

.SummaryPaneContent .buttons .mails[disabled]:hover, .SummaryPaneContent .buttons .mails:hover,
.SummaryPaneContent .buttons .mails[disabled]:active, .SummaryPaneContent .buttons .mails:active,
.SummaryPaneContent .buttons .mails[disabled]:focus, .SummaryPaneContent .buttons .mails:focus {
    background-color: #00a900;
}

.name_title_doctor{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}

.tooltip-wrapper {
    display: inline-block; /* display: block works as well */
    margin: 50px; /* make some space so the tooltip is visible */
}

.tooltip-wrapper .btn[disabled] {
    /* don't let button block mouse events from reaching wrapper */
    pointer-events: none;
}

.tooltip-wrapper.disabled {
    /* OPTIONAL pointer-events setting above blocks cursor setting, so set it here */
    cursor: not-allowed;
}

@media (max-width: 375px) {
    .SummaryPaneContent .notify-guests span {
        font-size: 13.5px;
    }
}

@media (max-width: 700px){
    .tui-full-calendar-dayname-name{
        display: none;
    }
    .Secretariat .calendar-type{
        float: none;
        margin: 10%;
    }
    .move-today{
        float: right;
    }
}