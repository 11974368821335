.AppContent {
    width: 100%;
    min-height: 85vh;
    position: absolute;
    left: 0;
    background-color: #37ab49;
    background-image: url(../../static/images/ILLUSTRATION2.png), url(../../static/images/ILLUSTRATION1.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    background-size: 57%, 38%;
}

@media (max-width: 768px) {

    .AppContent {
        background-image: url(../../static/images/ILLUSTRATION2BIS.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media (min-width: 1678px) {

    .AppContent {
        background-image: url(../../static/images/ILLUSTRATION2.png), url(../../static/images/ILLUSTRATION1.png);
        background-position: left top, right bottom;
        background-repeat: no-repeat;
        background-size: 50%, 33%;
    }
}

@media (min-width: 1200px) {

    .AppContent {
        width: 100%;
        min-height: 85vh;
        position: absolute;
        left: 0;
        background-color: #37ab49;
        background-image: url(../../static/images/ILLUSTRATION2.png), url(../../static/images/ILLUSTRATION1.png);
        background-position: left top, right bottom 30%;
        background-repeat: no-repeat;
        background-size: 57%, 38%;
    }
}