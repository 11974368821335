.RoomAdmin {
    margin: 0;
    height: 100%;
    overflow: hidden
}

.RoomAdmin .container__img img {
    width: 215px;
    height: auto;
}

.RoomAdmin .container__text {
    font-size: 20px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RoomAdmin > div > div > div > div > div {
    top: -30px;
    right: -1%;
}

@media all and (min-width: 480px) {
    .RoomAdmin {
        padding: 60px 0;
    }
}

@media (min-width: 768px) and (max-width: 1290px) {
    .fields, .create-image {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1381px) {
    .RoomAdmin > div > div > div {
        max-width: 700px;
        margin: 0 auto;
    }
}
