.Admin {
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    width: 96.5vw;
}

.Admin > div > div {
    margin-left: 20%;
    flex: none;
    max-width: 60%;
}

.section:hover {
    opacity: 0.5;
}

@media (max-width: 768px) {
    .Admin > div > div {
        margin-left: 0;
        flex: none;
        max-width: 100%;
    }
}