.room_created__text {
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin-bottom: 7px;
}

@media (max-width: 1290px) {
    .room_created__text {
        text-align: center;
    }
}