.UserList {
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    padding-top: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 96.5vw;
    font-size: 12px;
    overflow-x: hidden;
}

.AddUserPaneContent {
    font-size: 12px;
}

.AddUserPaneContent .remove {
    cursor: pointer;
    color: red;
}

.AddUserPaneContent .add {
    cursor: pointer;
    color: green;
}

.AddUserPaneContent .doctor-selector {
    margin-bottom: 10px;
}

.table_title {
    background-color: #0079c2;
    color: white;
}

.home-img {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    padding: 17px;
    border: 1px solid black;
    border-radius: 20px;
}

.home-img img {
    width: 30px
}

.home-img:hover {
    background-color: white;
}

.list_dash {
    border-bottom: 1px solid #D6D6D6;
}

.mg_field {
    margin: 10px 0 10px 10px;
}

.pointer {
    cursor: pointer;
}

.new-user {
    font-size: 12px;
    padding: 5px;
    margin-left: auto;
}

.delete-user {
    float: left;
}

.update-user {
    float: right;
}

.upper-page h1 {
    float: left;
}

.upper-page {
    margin-bottom: 85px;
}

.status {
    text-align: right;
}

.buttons-bellow-upper-page .users, .buttons-bellow-upper-page .roles {
    padding: 5px;
}

.buttons-bellow-upper-page {
    margin-bottom: 15px;
}

.delete[disabled]:hover, .delete:hover,
.delete[disabled]:active, .delete:active,
.delete[disabled]:focus, .delete:focus {
    background-color: red;
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div > div > div > div > div > div > div.col-lg-7.col-md-7.col-sm-12.col-xs-12 > form > div > div:nth-child(4) > div > div:nth-child(2) {
    position: inherit !important;
}

.UserList > div > div > div > div > div > div > div > div > div > div > div > div > div h5{
    font-size: 22px;
    font-weight: 450;
}

#Create > div > div:nth-child(6) > div > div:nth-child(2) {
    position: inherit !important;
}

#myInput {
    background-image: url('../../static/images/searchIcon.png');
    background-size: 22px;
    background-position: 10px 8px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 14px;
    padding: 8px 20px 8px 40px;
    border: 1px solid #ddd;
    margin-bottom: 8px;
}

#Update > div > div:nth-child(4) > div > div > div:nth-child(2) {
    position: inherit !important;
}

#Update > div > div:nth-child(4) > div.float-right.form-group > div > div:nth-child(2) {
    right: 0;
}

#Update > div > div > .status > div > div > div > div > div > div > input[type=checkbox] {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

#Update > div > div > .status > div > div > div > div > div > div > input[type=checkbox]:checked {
    background-color: gray;
}

#Update > div > div:nth-child(4) > div.float-left.form-group > div > div:nth-child(2) > div > div.css-ik6y5r > div {
    width: 200px;
}

.AddUserPaneDialog h3 {
    margin-bottom: 30px;
}

.AddUserPaneDialog .close-button {
    margin-top: -60px !important;
}

.UserPane label span {
    position: relative;
    bottom: 3px;
    margin-right: 5px;
    font-size: 12px;
}

.UserPane label > div {
    height: 17px;
}

.UserPane .status-locked, .UserPane .status-unlocked{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
}

.CreateUserPane .PhoneInput input,
.UserPane .PhoneInput input {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    font-size: 16px;
    border: 1px solid #d6d4d4;
    border-radius: 3px;
}

.CreateUserPane .PhoneInput .PhoneInputCountrySelect,
.UserPane .PhoneInput .PhoneInputCountrySelect {
    font-size: 12px;
}

.CreateUserPane .PhoneInput .PhoneInputCountryIcon,
.UserPane .PhoneInput .PhoneInputCountryIcon {
    height: 2em;
    width: 3em;
}

.CreateUserPane .PhoneInput .PhoneInputCountrySelectArrow,
.UserPane .PhoneInput .PhoneInputCountrySelectArrow {
    height: 0.45em;
    width: 0.45em;
}

.CreateUserPane #Create > div > div:nth-child(7) > div > div:nth-child(2) {
    position: relative !important;
}

.UserPane #Update > div > div:nth-child(7) > div > div:nth-child(2) {
    position: relative !important;
}


.css-11unzgr{
    max-width: 100%;
    overflow-x: hidden;
}



@media only screen and (max-width: 700px) {
    .mg_field{
        margin-left: 10px;
    }
}

@media only screen and (max-width: 1000px) {
    .mg_field{
        margin-left: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1146px) {
    .upper-page .create-user {
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        margin: 0 auto;
        float: none !important;
    }

    .upper-page {
        margin-bottom: 20px;
    }
}

@media (max-width: 557px) {
    .upper-page .create-user {
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        margin: 0 auto;
        float: none !important;
    }

    .upper-page {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .home-img {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        padding: 10px;
        border: 1px solid black;
        border-radius: 10px;
    }

    .home-img img {
        width: 20px
    }
}

@media (max-width: 374px) {
    .status {
        float: left !important;
    }
}