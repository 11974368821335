.Settings {
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    width: 96.5vw;
    overflow-x: hidden;
}

.settings_section > div > div {
    margin-left: 20px;
    font-size: 12px;
}

.settings_section h4 {
    margin-left: 20px;
}

.Settings .invitation-email {
    border: 1px solid gray;
}

.Settings .invitation-email a {
    font-size: 15px;
}

.Settings .help-image {
    position: absolute;
    display: initial;
    right: 20px;
    cursor: pointer;
}

.update-settings {
    float: right;
    margin-top: -25px;
}

.quantity-picker {
    margin-left: 20px;
    display: inline-block;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    vertical-align: middle;
}

.quantity-modifier,
.quantity-display {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
}

.quantity-display {
    width: 5.45rem;
    padding: .5rem;
    font-size: 1.5rem;
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
}

.quantity-modifier {
    height: 100%;
    width: 3rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5rem;
    background: #f3f3f3;
    color: #888;
    border: 0 solid #dbdbdb;
    text-align: center;
    cursor: pointer;
}

.quantity-modifier:hover {
    background: #dadada;
    color: #555555;
}

.mod-disable {
    color: #E0E0E0;
}

.mod-disable:hover {
    background-color: #f3f3f3 !important;
    color: #E0E0E0;
}