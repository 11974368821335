.Help {
    margin: 0 auto;
    padding-top: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    width: 96.5vw;
    position: absolute;
    left:0;
    right:0;
    overflow-x: hidden;
}

.Help video {
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.Help .container__title {
    color: black;
    padding: 30px;
    text-align: center;
    font-size: 30px;
}

.Help .container__title .line {
    width: 50px;
    height: 2px;
    background: black;
    margin: 0 auto;
}