.Profile {
    margin: 50px 0 0;
    height: 100%;
    overflow: hidden;
}

.Profile .profile-content {
    margin-bottom: 15px;
    text-align: left;
}

.Profile .profile-content button {
    text-align: left;
}

.Profile .profile-title {
    border: 1px solid black;
    border-radius: 12px;
}

.Profile .information {
}

.Profile .user-information {
    font-size: 13px;

}

.Profile .user-information strong {
    margin-left: 10px;
}

.Profile .codes {
    font-size: 12px;
    margin-bottom: 10px;
}

.align_map{
    display: inline;
}

.img_profil{
    margin-top: 10%;
    margin-left: 15% !important;
}
.title_1 {
    margin-left: 2%;
    display: inline;
}

.title_2 {
    margin-left: 15%;
    display: inline;
}

.user_name_info{
    font-size: 16px;
    margin-left: -2%;
}
.user_name_info_2{
    font-size: 16px;
    text-align: left;
}

.first_line_name{
    margin-top: 2%;
    margin-left: -2%;
}



@media (min-width: 1000px) {
    .second_info{
        margin-top: -8%;
        margin-left: -3%;
    }
}

@media (min-width: 769px) and (max-width: 1040px) {
    .title_1{
        margin-left: 30%;
        display: block;
    }
    .title_2{
        margin-left: 30%;
    }
}

@media (max-width: 1000px) {

    .user_name_info{
        margin-left: 5%;
    }
    .margin-left{
        display: none;
    }
}


@media (max-width: 768px) {
    .user_name_info {
        margin-left: 5%;
    }
    .user_name_info_2{
        margin-left: 5%;
    }
    .title_1{
        margin-left: 5%;
    }
}




.margin-left{
    flex: 25%;
}