
.Home {
    margin: 0;
    height: 100%;
    overflow: hidden
}

.create-room {
    margin-top: 18%;
    margin-left: 39vw;
}

.join-room {
    margin-top: 8%;
    margin-left: auto;
    margin-right: 38vw;
}

@media (max-width: 360px) {
    .container__img .button {
        padding: 15px 20px 15px 20px;
        font-size: 1em;
    }

    .create-room {
        position: relative;
        margin-top: 230px;
        margin-left: 55vw;
    }

    .join-room {
        position: relative;
        margin-top: 40px;
        margin-left: 5vw;
    }
}

@media (min-width: 361px) and (max-width: 480px) {
    .container__img .button {
        padding: 15px 40px 15px 40px;
        font-size: 1em;
    }

    .create-room {
        position: relative;
        margin-top: 55%;
        margin-left: 55vw;
    }

    .join-room {
        position: relative;
        margin-top: 13%;
        margin-left: 2%;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .create-room {
        position: relative;
        margin-top: 200px;
        margin-left: 50vw;
    }

    .join-room {
        position: relative;
        margin-top: 100px;
        margin-right: 35vw;
    }
}

@media (min-width: 769px) and (max-width: 1279px) {
    .create-room {
        position: relative;
        margin-top: 15%;
        margin-left: 38vw;
    }

    .join-room {
        position: relative;
        margin-top: 5%;
        margin-left: 3%;
    }
}

@media (min-width: 1280px) and (max-width: 1381px) {
    .create-room {
        position: relative;
        margin-top: 260px;
        margin-left: 39vw;
    }

    .join-room {
        position: relative;
        margin-top: 120px;
        margin-right: 36vw;
    }
}

@media (min-width: 1382px) {
    /*  .create-room {
          position: relative;
          margin-top: 330px;
          margin-left: 34vw;
      }*/

    .join-room {
        position: relative;
        margin-top: 8%;
        margin-right: 34vw;
    }
}


@media (min-width: 700px) and (max-width: 850px) and (max-height: 1150px){
    .create-room {
        margin-top: 32% !important;
        padding-left: 59px;
    }

    .join-room {
        position: relative;
        margin-top: 17%;
        margin-left: 10%;
    }
}

@media (min-width: 769px) and (max-width: 1279px) and (max-height: 850px){
    .create-room {
        position: relative;
        margin-top: 15%;
        margin-left: 38vw;
    }

    .join-room {
        position: relative;
        margin-top: 10%;
        margin-left: 10%;
    }
}