.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1400;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
  opacity: 0.8;
}

.side-drawer .items {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.side-drawer li {
  margin: 0 auto 15px auto;
}

.side-drawer a {
  color: black;
  text-decoration: none;
  font-size: 20px;
  margin: 0 5px 0 5px;
}

.side-drawer li:hover a,
.side-drawer a.active {
  color: white;
  background-color: #4e9ed0;
}

.side-close-button {
  color: #007bff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 20px !important;
  box-sizing: border-box;
  float: right !important;
}

.side-close-button:hover {
  color: #0056b3;
}

.side-close-button:focus {
  outline: none;
}

@media (min-width: 992px) {
    .side-drawer {
        display: none;
    }
}
